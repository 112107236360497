import Form from 'react-bootstrap/Form';
import './CommonForm.css';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { InvitationAPI } from '../../../internal/services/InvitationAPI';
import { invitationForm } from '../../../internal/utils/InvitationForms';
import InvitationContext from '../../context/InvitationContext';
import { useCookies } from 'react-cookie';

const SearchByGuestNameForm = ({ changeActiveForm }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const { t } = useTranslation();
    const { setInvitation } = useContext(InvitationContext);
    const [, setCookie] = useCookies(['invitationId']);
    const [guestId, setGuestId] = useState("");


    const handleSearch = () => {
        setIsLoading(true);

        const cachedGuests = sessionStorage.getItem('cachedGuests');
        const cacheTimestamp = sessionStorage.getItem('cacheTimestamp');
        const now = new Date().getTime();

        if (cachedGuests && cacheTimestamp && (now - cacheTimestamp < 5 * 60 * 1000)) {
            setOptions(JSON.parse(cachedGuests));
            setIsLoading(false);
            return;
        }

        InvitationAPI.getAllGuest()
            .then((resp) => {
                if (!resp) {
                    setOptions([]);
                    return;
                }
                sessionStorage.setItem('cachedGuests', JSON.stringify(resp));
                sessionStorage.setItem('cacheTimestamp', now);
                setOptions(resp);
                const filteredResp = resp.filter(guest => guest.fullName);
                setOptions(filteredResp);
                setIsLoading(false);
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (guestId !== "") {
            InvitationAPI.getInvitationById(guestId)
                .then((resp) => {
                    // Set invitation obj in the context
                    setInvitation(resp)
                    // If we are here it means no cookie were set
                    // TODO create a util for cookie expiration
                    let date = new Date();
                    date.setDate(date.getDate() + 400);
                    setCookie("invitationId", guestId, { expires: date })
                    changeActiveForm(invitationForm.guestTabForm)
                })
                .catch((response) => {
                    console.log(response);
                });
        }


    }

    return (
        <div className='formRespondNow'>
            <p className='labelTop'>{t("enterName")}</p>
            <Form.Group>
                <AsyncTypeahead
                    className='inputTextBox'
                    id="async-typeahead-sbn"
                    isLoading={isLoading}
                    labelKey="fullName"
                    minLength={1}
                    onSearch={handleSearch}
                    options={options}
                    placeholder={t("searchByNameInputBox")}
                    renderMenuItemChildren={(option) => (
                        <>
                            <span>{option.fullName}</span>
                        </>
                    )}
                    onChange={(selected) => {
                        if (selected.length > 0) {
                            setGuestId(selected[0].invitationId);
                        }
                    }}
                />
            </Form.Group>
            <Button className='response-button' type="submit" onClick={handleSubmit}>{t("submit")}</Button>
        </div>
    );
};

export default SearchByGuestNameForm;