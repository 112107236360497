import React from "react";
import '../../index.css';
import { useTranslation } from "react-i18next";

const Planning = () => {
    const { t } = useTranslation();
    return (
        <div className="planning">
            <div className="planning-image-container"  >
                <img className="planning-image" src="/images/colonne-fontdouce.jpg" alt="Planning" />
            </div>
            <div className="planning-list">
                <h1 style={{ fontWeight: 700 }}>{t('planning.title')}</h1>
                <div>
                    <h2> <img className="planning-icon" src="/images/wedding-arch.png" alt="Ceremony" /> {t('planning.ceremony')}</h2>
                </div>
                <div>
                    <h2> <img className="planning-icon" src="/images/photo.png" alt="Planning"/> {t('planning.photo')}</h2>
                </div>
                <div>
                    <h2> <img className="planning-icon" src="/images/champagne.png" alt="Reception" />{t('planning.reception')}</h2>
                </div>
                <div>
                    <h2> <img className="planning-icon" src="/images/dinner.png" alt="Meal" /> {t('planning.meal')}</h2>
                </div>
                <div>
                    <h2><img className="planning-icon" src="/images/dance.png" alt="Dancing" />{t('planning.dance')}</h2>
                </div>
                <div>
                    <h2><img className="planning-icon" src="/images/croissant.png" alt="Brunch" />{t('planning.brunch')}</h2>
                </div>
            </div>
        </div>
    );
};

export default Planning;